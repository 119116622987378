export const EVENT_MINICART_OPEN = 'minicart:open';
export const EVENT_MINICART_OPENED = 'minicart:opened';
export const EVENT_MINICART_CLOSE = 'minicart:close';
export const EVENT_MINICART_CLOSED = 'minicart:closed';
export const EVENT_MINICART_INITIALIZED = 'minicart:initialized';
export const EVENT_MINICART_UPDATED = 'minicart:updated';
export const EVENT_MINICART_BEFORE_UPDATED = 'minicart:before:updated';
export const EVENT_MINICART_PRODUCT_ADD = 'minicart:product:add';
export const EVENT_MINICART_PRODUCT_REMOVE = 'minicart:product:remove';
export const EVENT_MINICART_PRODUCTS_UPDATE = 'minicart:products:update';
export const EVENT_MINICART_PRODUCTS_CHANGE = 'minicart:products:change';
export const EVENT_MINICART_PRODUCTS_REMOVE = 'minicart:products:remove';
export const EVENT_MINICART_MEMBERSHIP_ADD = 'minicart:membership:add';
export const EVENT_MINICART_MEMBERSHIP_REMOVE = 'minicart:membership:remove';
export const EVENT_MINICART_RELOAD = 'minicart:reload';
export const EVENT_MINICART_RELOAD_SILENTLY = 'minicart:reload:silently';
export const EVENT_MINICART_ERROR = 'minicart:error';
export const EVENT_MINICART_ITEMS_COUNT = 'minicart:item_count';

export const EVENT_WISHLIST_INITIALIZED = 'wishlist:initialized';
export const EVENT_WISHLIST_ADD = 'wishlist:add';
export const EVENT_WISHLIST_REMOVE = 'wishlist:remove';
export const EVENT_WISHLIST_TOGGLE = 'wishlist:toggle';

export const EVENT_WISHLIST_UPDATED = 'wishlist:updated';
